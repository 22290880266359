/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';

// @mui core components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Argon Dashboard 2 PRO MUI components
import ArgonBox from 'components/ArgonBox';
import ArgonTypography from 'components/ArgonTypography';
import ArgonSelect from 'components/ArgonSelect';
import ArgonTagInput from 'components/ArgonTagInput';

// Settings page components
import FormField from 'layouts/pages/account/components/FormField';

// Data
import selectData from 'options/selectData';
import ArgonButton from 'components/ArgonButton';

// import { useFormik } from 'formik';
// import * as Yup from 'yup';
import { Alert, Switch, Table, TableBody, TableRow } from '@mui/material';

import TableCell from 'components/TableCell';

import { useArgonController } from 'context';
import DeleteIcon from '@mui/icons-material/Delete';
import EventLogo from './components/EventLogo';
// import CreateEventInstances from './components/CreateEventInstances';
import SelectOrganization from 'Routes/Organizer/Components/SelectOrganization';
import FormikTextInput from 'components/FormikTextInput';

function BasicInfo({
  formik,
  onDeleteEvent,
  onSaveEvent,
  onUpdateEventLogoUrl,
  noGutter,
  onCreateNewEventInstance,
  enableLogoUpload = true
}) {
  const [controller] = useArgonController();
  const { darkMode } = controller;

  const handleTextfieldChanged = (event) => {
    formik.handleChange(event);
  };

  const handleSwitchChanged = async (event) => {
    formik.setFieldValue(event.target.name, event.target.checked);
  };

  const handleSelectMenuChanged = (fieldName, option) => {
    formik.setFieldValue(fieldName, option);
  };

  const handleLogoUploaded = (url) => {
    formik.setFieldValue('pic_logo_url', url);
    onUpdateEventLogoUrl && onUpdateEventLogoUrl(url);
  };

  const handleSaveEventButton = () => {
    onSaveEvent && onSaveEvent(formik.values);
  };

  const handleDeleteEventButton = () => {
    onDeleteEvent && onDeleteEvent();
  };

  if (!formik.values) return <div>Loading...</div>;

  return (
    <>
      <Card id="basic-info" sx={{ overflow: 'visible' }}>
        <ArgonBox p={3}>
          <ArgonTypography variant="h5">Event Setup</ArgonTypography>
        </ArgonBox>
        <ArgonBox pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid container item spacing={3}>
              <Grid
                item
                xs={12}
                sm={enableLogoUpload ? 8 : 12}
                style={{
                  paddingTop: '12px',
                  alignItems: 'flex-start'
                }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <ArgonBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%">
                      {/* <ArgonBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block">
                        <ArgonTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize">
                          Organization
                        </ArgonTypography>
                      </ArgonBox> */}
                      <SelectOrganization
                        id="organizationId"
                        name="organizationId"
                        title="Select your organization"
                        required
                        size="regular"
                        showLoader
                        onChange={(orga) =>
                          handleSelectMenuChanged('organizationId', orga.value)
                        }
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <ArgonBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%">
                      <ArgonBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block">
                        <ArgonTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize">
                          sports <span style={{ color: 'red' }}>*</span>
                        </ArgonTypography>
                      </ArgonBox>
                      <ArgonSelect
                        id="sports"
                        name="sports"
                        placeholder="Please choose"
                        options={selectData.sportsType}
                        value={selectData.sportsType.filter(
                          (option) => option.value === formik.values.sports
                        )}
                        onChange={(option) =>
                          handleSelectMenuChanged('sports', option.value)
                        }
                        error={
                          formik.touched.sports && Boolean(formik.errors.sports)
                        }
                        helperText={
                          formik.touched.sports && formik.errors.sports
                        }
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <ArgonBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%">
                      <ArgonBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block">
                        <ArgonTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize">
                          event type <span style={{ color: 'red' }}>*</span>
                        </ArgonTypography>
                      </ArgonBox>
                      <ArgonSelect
                        id="type"
                        name="type"
                        placeholder="Please choose"
                        options={selectData.eventType}
                        value={selectData.eventType.filter(
                          (option) => option.value === formik.values.type
                        )}
                        onChange={(option) =>
                          handleSelectMenuChanged('type', option.value)
                        }
                        error={
                          formik.touched.type && Boolean(formik.errors.type)
                        }
                        helperText={formik.touched.type && formik.errors.type}
                      />
                    </ArgonBox>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    {/* <FormField
                      id="name"
                      name="name"
                      label="Event Title"
                      placeholder="Your event name"
                      required
                      value={formik.values.name}
                      onChange={handleTextfieldChanged}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    /> */}
                    <FormikTextInput
                      id="name"
                      name="name"
                      required
                      label="Event Title"
                      placeholder="Your event name"
                      formik={formik}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {enableLogoUpload && (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{
                    // paddingTop: '12px',
                    alignItems: 'flex-start'
                  }}>
                  <EventLogo
                    eventData={formik.values}
                    onLogoUploaded={handleLogoUploaded}
                  />
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormikTextInput
                id="descr_short"
                name="descr_short"
                label="Subtitle"
                placeholder="A short text to show as subtitle"
                formik={formik}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Table sx={{ minWidth: '36rem' }}>
                <ArgonBox component="thead">
                  <TableRow>
                    <TableCell width="100%" padding={[1, 1, 1, 0.5]}>
                      Search parameters (only used for finding events on the
                      website)
                    </TableCell>
                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                      N/Y
                    </TableCell>
                  </TableRow>
                </ArgonBox>
                <TableBody>
                  <TableRow>
                    <TableCell padding={[1, 1, 1, 0.5]}>
                      <ArgonBox lineHeight={1.4}>
                        <ArgonTypography
                          display="block"
                          variant="caption"
                          fontWeight="bold">
                          For individuals
                        </ArgonTypography>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="regular">
                          Competition offers separate categories for individuals
                        </ArgonTypography>
                      </ArgonBox>
                    </TableCell>
                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                      <Switch
                        id="forIndividuals"
                        name="forIndividuals"
                        value
                        onChange={handleSwitchChanged}
                        checked={formik.values.forIndividuals}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell padding={[1, 1, 1, 0.5]}>
                      <ArgonBox lineHeight={1.4}>
                        <ArgonTypography
                          display="block"
                          variant="caption"
                          fontWeight="bold">
                          For teams
                        </ArgonTypography>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="regular">
                          Competition offers separate categories for teams
                        </ArgonTypography>
                      </ArgonBox>
                    </TableCell>
                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                      <Switch
                        id="forTeams"
                        name="forTeams"
                        onChange={handleSwitchChanged}
                        checked={formik.values.forTeams}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell padding={[1, 1, 1, 0.5]}>
                      <ArgonBox lineHeight={1.4}>
                        <ArgonTypography
                          display="block"
                          // variant="button"
                          // fontWeight="regular"
                          variant="caption"
                          fontWeight="bold">
                          For kids
                        </ArgonTypography>
                        <ArgonTypography
                          variant="caption"
                          color="text"
                          fontWeight="regular">
                          Competition offers separate categories for kids
                        </ArgonTypography>
                      </ArgonBox>
                    </TableCell>
                    <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                      <Switch
                        id="forUnderage"
                        name="forUnderage"
                        onChange={handleSwitchChanged}
                        checked={formik.values.forUnderage}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={12} sm={12}>
              <ArgonBox display="flex" justifyContent="space-between">
                <ArgonButton
                  id="btnSubmit"
                  variant="gradient"
                  color="info"
                  size="small"
                  loading={formik.isSubmitting}
                  onClick={handleSaveEventButton}>
                  {formik.values._id === undefined ||
                  formik.values._id === null ||
                  formik.values._id === ''
                    ? 'Next Step'
                    : 'Save Event'}
                </ArgonButton>
                <ArgonButton
                  id="btnDelete"
                  variant="text"
                  color="error"
                  onClick={handleDeleteEventButton}>
                  <DeleteIcon />
                  &nbsp;Delete
                  {/* <Icon>delete</Icon>&nbsp;Delete */}
                </ArgonButton>
              </ArgonBox>
            </Grid>
          </Grid>
        </ArgonBox>
      </Card>
      {/* 
      <CreateEventInstances
        onCreateNewEventInstance={onCreateNewEventInstance}
      /> */}
    </>
  );
}

export default BasicInfo;
